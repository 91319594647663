import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Box, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';

import CapabilityStatement from './pages/CapabilityStatement.jsx';
import ContactMe from './pages/ContactMe.jsx';
import Home from './pages/Home.jsx';
import RecommendedLiterature from './pages/RecommendedLiterature.jsx';
import Vlog from './pages/Vlog.jsx';
import WorkSamples from './pages/WorkSamples.jsx';
import Artefacts from './pages/Artefacts.jsx';
import Resume from './pages/Resume.jsx';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

import './styles.css';
import customTheme from './theme';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const footerHeight = isMobile ? 56 : 64; // Responsive footer height
  const navBarHeight = isMobile ? 56 : 64; // Responsive navbar height

  return (
    <ThemeProvider theme={customTheme}>
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center' }}>
          <NavBar navBarHeight={navBarHeight} />
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: '16px 10px',
              pt: `${navBarHeight + 16}px`,
              pb: `${footerHeight + 16}px`,
              overflow: 'auto'
            }}
          >

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/capability-statement" element={<CapabilityStatement />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/work-samples" element={<WorkSamples />} />
              <Route path="/artefacts" element={<Artefacts />} />
              <Route path="/recommended-literature" element={<RecommendedLiterature />} />
              <Route path="/vlog" element={<Vlog />} />
              <Route path="/contact-me" element={<ContactMe />} />
            </Routes>
          </Box>
          <Footer footerHeight={footerHeight} />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
