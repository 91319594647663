// Education.js
import React from 'react';
import { AccordionDetails, Typography } from '@mui/material';

const Education = () => {
  return (
    <AccordionDetails>
      <Typography>
        Maharaj Vinayak Global University<br />
        Bachelor in Dental Surgery, 2016 - 2021 | Jaipur, India | Division: First<br />
        - Public Health Dentistry<br />
        - Periodontology<br />
        - Pediatric and Preventive Dentistry<br />
        - Conservative Dentistry and Endodontics
      </Typography>
      <hr></hr>
      <Typography>
        Western Sydney University<br />
        Masters in Public Health, 2022 - 2024 | Sydney, Australia | Ongoing<br />
        - Biostatistics<br />
        - Health Policy and System<br />
        - Epidemilogy<br />
        - Work Health and Safety
      </Typography>
    </AccordionDetails>
  );
};

export default Education;
