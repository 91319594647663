// Experience.js
import React from 'react';
import { AccordionDetails, Typography } from '@mui/material';

const Experience = () => {
  return (
    <AccordionDetails>
      <Typography>
        Jaipur Dental College and Hospital<br />
        Clinical Rotations (1 year), 2020 - 2021 | Jaipur, India<br />
        Studied and practiced in 8 rotations covering various dental specialties.<br />
        Selected appropriate methods for pain and anxiety management and communicated goals with patients.<br />
        Examined, diagnosed, and treated 500+ patients with a wide array of oral care needs.<br />
        Organized and coordinated 2 dental camps in villages, campaigned for oral health awareness, and performed consultations and procedures for villagers.
      </Typography>
    </AccordionDetails>
  );
};

export default Experience;
