import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Education from '../components/Education.jsx';
import Experience from '../components/Experience.jsx';
import Skills from '../components/Skills.jsx';
import Summary from '../components/Summary.jsx';

const Resume = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 10 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md="auto">
            <Typography variant="h4" gutterBottom>
              Resume
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid item>
              <Button variant="contained" style={ { backgroundColor: "#266400"}} href="/assets/OjaswiThapa_Resume.pdf" download="OjaswiThapa_Resume.pdf">
                Download Resume
              </Button>
            </Grid>
          )}
        </Grid>
        <Typography variant="body1" paragraph>
          Here is a timeline of my professional experience.
        </Typography>
        {!isMobile && (
          <>
            <Container>
              <Typography variant="h5" sx={{ borderBottom: '2px solid #333', paddingBottom: '10px' }} gutterBottom>
                Summary
              </Typography> 
              <Summary />
            </Container>
            <Container>
              <Typography variant="h5" sx={{ borderBottom: '2px solid #333', paddingBottom: '10px' }} gutterBottom>
                Experience
              </Typography>
              <Experience />
            </Container>
            <Container>
              <Typography variant="h5" sx={{ borderBottom: '2px solid #333', paddingBottom: '10px' }} gutterBottom>
                Education
              </Typography>
              <Education />
            </Container>
            <Container>
              <Typography variant="h5" sx={{ borderBottom: '2px solid #333', paddingBottom: '10px' }} gutterBottom>
                Skills
              </Typography>
              <Skills />
            </Container>
          </>
        )}
        {isMobile && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="summary-content"
                id="summary-header"
              >
                <Typography variant="h3">Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Summary />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="experience-content"
                id="experience-header"
              >
                <Typography variant="h3">Experience</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Experience />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="education-content"
                id="education-header"
              >
                <Typography variant="h3">Education</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Education />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="skills-content"
                id="skills-header"
              >
                <Typography variant="h3">Skills</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Skills />
              </AccordionDetails>
            </Accordion>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" style={ { backgroundColor: "#266400"}} fullWidth href="/path/to/your/resume.pdf" download="YourName_Resume.pdf">
                Download Resume
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Resume;
