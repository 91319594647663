// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#002664', // Deep blue
    },
    secondary: {
      main: '#4CAF50', // Fresh green
    },
    third:{
        main: '#FFD166', // Yellow
    }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h6: {
      fontWeight: 600,
    },
    body1: {
      color: '#333333', // Dark grey
    },
  },
});

export default theme;
