// Skills.js
import React from 'react';
import { AccordionDetails, Typography } from '@mui/material';

const Skills = () => {
  return (
    <AccordionDetails>
      <Typography>
        - Comprehensive understanding of healthcare, spanning from clinical practice to community-focused approaches<br />
        - Experience in patient care and treatment, including oral health assessments, diagnosis, and treatment planning<br />
        - Ability to communicate effectively with patients, colleagues, and community members to promote health education and awareness<br />
        - Knowledge of public health principles and practices, including epidemiology, health policy, and program planning and evaluation<br />
        - Analytical skills to interpret data and research findings, identify health disparities, and develop evidence-based interventions<br />
        - Interdisciplinary collaboration skills to work with diverse healthcare professionals, policymakers, and community stakeholders<br />
        - Adaptability and resilience to navigate complex healthcare systems and address emerging public health challenges
      </Typography>
    </AccordionDetails>
  );
};

export default Skills;
