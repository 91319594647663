import React from 'react';
import {
  Typography,
  Container,
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { CheckCircle, ExpandMore } from '@mui/icons-material';

const CapabilityStatement = () => {
  const values = [
    'Compassion - I always make an effort to help individuals in need and actively listen to them.',
    'Teamwork - I try to work well along with my coworkers, supervisors and appreciate the contributions of each individual.',
    'Equitability - I make sure all patients and team members have equitable care and opportunity.',
    'Respect - Regardless of one\'s circumstances or history, I try to treat everyone with respect and decency. In every encounter, I act with professionalism.',
    'Accountability - I own up to my errors and make an effort to fix them.',
    'Integrity - I make an effort to behave in the client\'s and patients\' best interests, avoiding conflicts of interest.',
    'Empathy - I make an effort to give patients compassionate care that considers their psychological and emotional needs.',
    'Diversity - I accept and value the distinctions between patients and coworkers.',
    'Privacy - I make sure to protect the confidentiality of client’s information and respect their privacy.',
    'Inquisitiveness - I seek for fresh information and keep abreast of the most recent advancements in the industry.'
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: { md: 10 }, mb: { xs: 4, md: 0 } }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md="auto">
            <Typography variant="h4" gutterBottom>
              Capability Statement
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <Button variant="contained" style={ { backgroundColor: "#266400"}} href="/assets/OjaswiThapa_CapabilityStatement.pdf" download="OjaswiThapa_CapabilityStatement.pdf">
              Download Full Statement
            </Button>
          </Grid>
        </Grid>
        <Card sx={{ mt: 4, mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
            <Typography variant="body1" paragraph>
              I am Ojaswi Thapa, a Dentist seeking employment as a Health Communication Specialist. My ability to contribute efficiently to health communication jobs emerges from a unique combination of clinical expertise from a career in dentistry and a comprehensive education in Public Health. I completed my Bachelor’s in Dentistry in 2021 from India and shortly thereafter immigrated to Australia from my home country Nepal to pursue my Master’s degree in Public Health from Western Sydney University, currently in my final semester. After coming to Australia, I started working in aged care as an Assistant in Nursing (AIN). I am confident that I have the skills necessary to become a successful health communication specialist.
            </Typography>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Values
            </Typography>
            {isMobile ? (
              values.map((value, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{value.split(' - ')[0]}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{value.split(' - ')[1]}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <List>
                {values.map((value, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircle color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default CapabilityStatement;
