import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const recommendationLetters = [
  {
    name: "Dr. Lokendra Singh Dagur",
    position: "Associate Professor, Jaipur Dental College",
    date: "March 8, 2024",
    image: "/assets/recommendations/recommendation1.jpg"
  },
  {
    name: "Dr. Anup Nagaraj",
    position: "Principal, Jaipur Dental College",
    date: "April 26, 2023",
    image: "/assets/recommendations/recommendation2.jpg"
  }
];

const certificates = [
    {
        issuer: "LinkedIn Learning",
        name: "Leading with values",
        certId: "8ab33a02df6f08bdd6b9068ffd7b7ba6af8318527a6595aa89059fb548a152eb",
        date: "May 25, 2024",
        image: "/assets/certificates/c1.jpg"
    },
    {
        issuer: "LinkedIn Learning",
        name: "Learning Personal Branding",
        certId: "3f1ac4292435b6c0d9334290fc28522c35bea8c3fa45b9793c5abfd7057b2fd8",
        date: "May 26, 2024",
        image: "/assets/certificates/c2.jpg"
    },
    {
        issuer: "LinkedIn Learning",
        name: "Values and Ethics: Case Studies in Action",
        certId: "df8cdfd2c4e36f7b34230e77864b5bc88c838a6d19127022cce565ef9a40f163",
        date: "May 25, 2024",
        image: "/assets/certificates/c3.jpg"
    },
    {
        issuer: "LinkedIn Learning",
        name: "Communicating Values",
        certId: "7f7737aa064abeefe574962159b1b787cec9a708d7e2872b353de64c6647c6ad",
        date: "May 25, 2024",
        image: "/assets/certificates/c4.jpg"
    },
    {
        issuer: "LinkedIn Learning",
        name: "Developing Self-Awareness",
        certId: "065ddac2e9c44bd6accd2684f6b0a826692d038679cd6c953157898a53a250a9",
        date: "May 25, 2024",
        image: "/assets/certificates/c5.jpg"
    }
];

const awards = [
    {
    name: "Best Employee of the Month",
    issuer: "Pembroke Lodge",
    date: "December, 2023",
    image: "/assets/certificates/employee_month.jpg"
    }
];

const Artefacts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Artefacts
        </Typography>
        <Typography variant="body1" paragraph>
          Here are my recommendation letters, awards and certificates.
        </Typography>
        
        <Typography variant="h4" gutterBottom>
          Recommendation Letters
        </Typography>
        {!isMobile && (
          <Grid container spacing={2}>
            {recommendationLetters.map((letter, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography variant="h5" gutterBottom>{letter.name}</Typography>
                <Typography variant="body1" paragraph>{letter.position}</Typography>
                <Typography variant="body2" paragraph>{letter.date}</Typography>
                <img src={letter.image} alt={`Recommendation Letter from ${letter.name}`} style={{ width: '100%' }} />
              </Grid>
            ))}
          </Grid>
        )}
        {isMobile && (
          <>
            {recommendationLetters.map((letter, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography variant="h5">{letter.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">{letter.position}</Typography>
                  <Typography variant="body2">{letter.date}</Typography>
                  <img src={letter.image} alt={`Recommendation Letter from ${letter.name}`} style={{ width: '100%' }} />
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}

        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
            Awards
        </Typography>
        {!isMobile && (
            <Grid container spacing={2}>
                {awards.map((award, index) => (
                <Grid item xs={12} md={6} key={index}>
                    <Typography variant="h5" gutterBottom>{award.name}</Typography>
                    <Typography variant="body1" paragraph>{award.issuer}</Typography>
                    <Typography variant="body2" paragraph>{award.date}</Typography>
                    <img src={award.image} alt={`Award from ${award.issuer}`} style={{ width: '100%' }} />
                </Grid>
                ))}
            </Grid>
            )}
            {isMobile && (
            <>
                {awards.map((award, index) => (
                <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`award-panel${index}-content`}
                    id={`award-panel${index}-header`}
                    >
                    <Typography variant="h5">{award.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography variant="body1">{award.issuer}</Typography>
                    <Typography variant="body2">{award.date}</Typography>
                    <img src={award.image} alt={`Award from ${award.issuer}`} style={{ width: '100%' }} />
                    </AccordionDetails>
                </Accordion>
                ))}
            </>
        )}
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
          Certificates
        </Typography>
        {!isMobile && (
          <Grid container spacing={2}>
            {certificates.map((cert, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography variant="h5" gutterBottom>{cert.issuer} | {cert.name}</Typography>
                <Typography variant="body1" paragraph>{cert.certId}</Typography>
                <Typography variant="body2" paragraph>{cert.date}</Typography>
                <img src={cert.image} alt={`Certificate from ${cert.issuer}`} style={{ width: '100%' }} />
              </Grid>
            ))}
          </Grid>
        )}
        {isMobile && (
          <>
            {certificates.map((cert, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`cert-panel${index}-content`}
                  id={`cert-panel${index}-header`}
                >
                  <Typography variant="h5">{cert.issuer}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">{cert.certId}</Typography>
                  <Typography variant="body2">{cert.date}</Typography>
                  <img src={cert.image} alt={`Certificate from ${cert.issuer}`} style={{ width: '100%' }} />
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Box>
    </Container>
  );
};

export default Artefacts;
