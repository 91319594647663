import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const work_samples = {
  "Policy Advocacy": {
    title: "Policy Advocacy",
    description: "In this assignment, I argued in favour of the pharmaceutical benefits scheme's 60-day drug distribution. The government launched a fantastic programme that will benefit a large deal of individuals who depend on medications nationwide, particularly those who have chronic illnesses. I was happy with the grades I got for this project.",
    file: "/assets/WorkSamples/PolicyAdvocacyStatement.pdf"
  },
  "Short Answers": {
    title: "Short Answers",
    description: "For this project, we were to respond to the questions and write about a scenario we encountered during our clinical rotation. I had previously discussed the instance in which a breakdown in communication with the patient resulted in an event following therapy.",
    file: "/assets/WorkSamples/ShortAnswers.pdf"
  }
};

const WorkSamples = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 10 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md="auto">
            <Typography variant="h4" gutterBottom>
              Work Samples
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" paragraph>
          Here are some samples of my work.
        </Typography>
        {!isMobile && (
          <Grid container spacing={1}>
            {Object.keys(work_samples).map((key, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="h5" gutterBottom>{work_samples[key].title}</Typography>
                <Typography variant="body1" paragraph>
                  {work_samples[key].description} 
                  <a href={work_samples[key].file} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}> ... Read More</a>
                </Typography>
                <iframe title={work_samples[key].title} src={work_samples[key].file} width="100%" height="500px" />
              </Grid>
            ))}
          </Grid>
        )}
        {isMobile && (
          <Box sx={{ overflowX: 'hidden' }}>
            {Object.keys(work_samples).map((key, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                >
                  <Typography variant="h5">{work_samples[key].title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" paragraph>
                    {work_samples[key].description} 
                    <a href={work_samples[key].file} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}> ... Read More</a>
                  </Typography>
                  <iframe title={work_samples[key].title} src={work_samples[key].file} width="100%" height="500px" />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Box>
      <Typography variant="h5" paragraph>
        References
      </Typography>
      <Typography variant="body1" paragraph>
        Australian Government Department of Health and Aged Care. (2023, September 26). About cheaper medicines. Australian Government Department of Health and Aged Care. <a href="https://www.health.gov.au/cheaper-medicines/about-cheaper-medicines" target="_blank" rel="noopener noreferrer">https://www.health.gov.au/cheaper-medicines/about-cheaper-medicines</a>
      </Typography>
      <Typography variant="body1" paragraph>
        Roebuck, M. C., Liberman, J. N., Gemmill-Toyama, M., & Brennan, T. A. (2011). Medication Adherence Leads To Lower Health Care Use And Costs Despite Increased Drug Spending. Health Affairs, 30(1), 91–99. <a href="https://doi.org/10.1377/hlthaff.2009.1087" target="_blank" rel="noopener noreferrer">https://doi.org/10.1377/hlthaff.2009.1087</a>
      </Typography>
      <Typography variant="body1" paragraph>
        Agency for Healthcare Research and Quality. (2019, September 7). Root cause analysis. Patient Safety Network. <a href="https://psnet.ahrq.gov/primer/root-cause-analysis" target="_blank" rel="noopener noreferrer">https://psnet.ahrq.gov/primer/root-cause-analysis</a>
      </Typography>
      <Typography variant="body1" paragraph>
        Lu, L., Ko, Y.-M., Chen, H.-Y., Chueh, J.-W., Chen, P.-Y., & Cooper, C. L. (2022). Patient safety and staff well-being: Organizational culture as a resource. International Journal of Environmental Research and Public Health, 19(6), 3722. <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8953540/" target="_blank" rel="noopener noreferrer">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8953540/</a>
      </Typography>
    </Container>
  );
};

export default WorkSamples;
