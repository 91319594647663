// src/components/Footer.js
import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const Footer = ({ footerHeight }) => {
  return (
    <AppBar position="fixed" color="primary" elevation={0} sx={{ top: 'auto', bottom: 0, height: `${footerHeight}px`, backgroundColor: '#f7f7f7', borderTop: '1px solid #ddd' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          © 2024 Ojaswi Thapa
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
