import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

const NavBar = ({ navBarHeight }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  
  const navLinks = [
    { label: 'Home', path: '/' },
    { label: 'Capability Statement', path: '/capability-statement' },
    { label: 'Resume', path: '/resume' },
    { label: 'Artefacts', path: '/artefacts' },
    { label: 'Work Samples', path: '/work-samples' },
    { label: 'Recommended Literature', path: '/recommended-literature' },
    { label: 'Vlog', path: '/vlog' },
    { label: 'Contact Me', path: '/contact-me' },
    
  ];

  const activeLinkStyle = {
    color: theme.palette.third.main,
    borderBottom: `2px solid ${theme.palette.third.main}`
  };

  return (
    <AppBar position="fixed" sx={{ height: `${navBarHeight}px`, background: `linear-gradient(180deg, #002664, #4D7AA4)` }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>Ojaswi Thapa</Link>
          </Typography>
          {isMobile ? (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          ) : (
            <div className="nav-links" style={{ display: 'flex', alignItems: 'center' }}>
              {navLinks.map((link) => (
                <Button
                  key={link.path}
                  color="inherit"
                  component={Link}
                  to={link.path}
                  sx={location.pathname === link.path ? activeLinkStyle : {}}
                >
                  {link.label}
                </Button>
              ))}
            </div>
          )}
        </Toolbar>
      </Container>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {navLinks.map((link) => (
            <ListItemButton
              key={link.path}
              component={Link}
              to={link.path}
              onClick={handleDrawerToggle}
              sx={location.pathname === link.path ? activeLinkStyle : {}}
            >
              <ListItemText primary={link.label} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default NavBar;
