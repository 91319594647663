// Summary.js
import React from 'react';
import { AccordionDetails, Typography } from '@mui/material';

const Summary = () => {
  return (
    <AccordionDetails>
      <Typography>
        Dedicated and compassionate dental professional with extensive experience in clinical practice and patient care. Skilled in performing comprehensive oral health assessments, diagnosis, and treatment planning using advanced dental instruments and equipment. Transitioned to public health to broaden expertise in improving population health outcomes and addressing community health disparities. Proficient in public health principles, including epidemiology, health policy, and program planning. Effective communicator with the ability to collaborate with multidisciplinary teams and stakeholders to develop and implement evidence-based interventions.
      </Typography>
    </AccordionDetails>
  );
};

export default Summary;
