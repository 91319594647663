import React from 'react';
import { Typography, TextField, Button, Box, Container, Grid, Link, Card, CardContent } from '@mui/material';

const ContactMe = () => (
  <Container maxWidth="lg">
    <Box sx={{ mt: { xs: 0, md: 10 }, mb: { xs: 0, md: 4 } }}>
      <Typography variant="h4" gutterBottom>Contact Me</Typography>
      
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            If you want to discuss an idea, or share any thoughts, feel free to reach out to me through the following contact details:
          </Typography>
          <Typography variant="body2">
            <strong>Email:</strong> ojaswithapa1998@gmail.com
          </Typography>
          <Typography variant="body2">
            <strong>LinkedIn:</strong> <Link href="https://www.linkedin.com/in/ojaswi-thapa" target="_blank" rel="noopener">linkedin.com/in/ojaswi-thapa</Link>
          </Typography>
          <Typography variant="body2">
            <strong>Contact Number:</strong> 0451126108
          </Typography>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="name"
                  label="Name"
                  variant="outlined"
                  name="name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  name="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="subject"
                  label="Subject"
                  variant="outlined"
                  name="subject"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="message"
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  name="message"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" type="submit" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  </Container>
);

export default ContactMe;
