import React from 'react';
import { Container, Typography , Box} from '@mui/material';

const Vlog = () => {
  return (
    <Container maxWidth="lg">
        
         <Box sx={{ mt: 10 }}>
      <Typography variant="h4" gutterBottom>
        Vlog
      </Typography>
      <Typography variant="body1" paragraph>
      Every correction offers us the chance to improve, grow, and raise the bar. By carefully going over and improving our work, we develop an attitude of constant improvement and put ourselves on the path to increased fulfilment and success.
        </Typography>
      <div style={{ position: 'relative', height: '0', width: "100%",paddingBottom: '56.25%', overflow: 'hidden', maxWidth: '100%' }}>
        <iframe 
          title="Embedded YouTube Video"
          width="100%" 
          height="100%" 
          src="https://www.youtube.com/embed/SM3LoBdW6M8" 
          frameBorder="0" 
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} 
        />
      </div>
     </Box>
    </Container>
  );
};

export default Vlog;
