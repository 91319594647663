import { Box, Button, Card, CardContent, CardMedia, Container, Typography } from '@mui/material';
import React from 'react';

const Home = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: { md: 10 } }}>
        <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', marginBottom: 4, boxShadow: 5, borderRadius: 8, padding: { xs: 1, md: 5 } }}>
          <CardMedia
            component="img"
            height="200"
            image="/assets/profilepic.png" 
            alt="Ojaswi Thapa's profile picture"
            sx={{ borderRadius: '50%', width: 250, height: 250, objectFit: 'cover', marginRight: { xs: 0, md: 4 }, marginBottom: { xs: 4, md: 0 } }}
          />
          <CardContent>
            <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'center' } }}> {/* Center align for xs screens only */}
              E-Portfolio!
            </Typography>
            <Typography variant="body1" sx={{ textAlign: { xs: 'justify' } }}> {/* Justify for xs screens only */}
              Hello, I am Ojaswi Thapa, and I am pleased to share my professional journey here with you. I come from a beautiful country, Nepal, which is nestled in the heart of the Himalayas and home to the world’s highest peak. I am currently residing in Sydney, where I am pursuing my Masters in Public Health from Western Sydney University.
              <br /><br />
              My academic background is rooted in dentistry, having completed my Bachelor’s degree in India. The blend of my background in dentistry alongside my studies in public health has equipped me with a holistic comprehension of healthcare, spanning from clinical practice to community-focused approaches.
            </Typography>
          </CardContent>
        </Card>
        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
          <Button href="/capability-statement" color="primary" variant="outlined" sx={{ marginRight: 2, '&:hover': { backgroundColor: 'transparent', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }, '@media (max-width:600px)': { textAlign: 'center' } }}>
            Capability Statement
          </Button>
          <Button href="/resume" color="primary" variant="outlined" sx={{ marginRight: 2, '&:hover': { backgroundColor: 'transparent', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }, '@media (max-width:600px)': { textAlign: 'center' } }}>
            Resume
          </Button>
          <Button href="/contact-me" color="primary" variant="outlined" sx={{ '&:hover': { backgroundColor: 'transparent', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' }, '@media (max-width:600px)': { textAlign: 'center' } }}>
            Contact Me
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
