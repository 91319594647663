import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

const recommendedLiterature = {
    "Book1": {
      title: "Essential Dental Public Health",
      description: "Daly, B., Batchelor, P., Treasure, E., & Watt, R. (2013). Oxford University Press.",
      comment: "An essential component of dentistry, dental public health is examined in this introduction along with its relevance to clinical practice. The book is structured into four sections: oral health promotion, epidemiology of oral diseases, oral health services, and philosophy. Throughout, it provides practical summaries, actionable advice, and recommended readings for deeper understanding. Suitable for those seeking comprehensive insights into dental public health.",
      image: "/assets/recommendations/b1.jpg",
      url: "https://doi.org/10.1093/oso/9780199679379.001.0001"
    },
    "Book3": {
      title: "Public Health Practice in Australia: The Organised Effort",
      description: "Lin, V., Smith, J., & Fawkes, S. (2007). Allen & Unwin Australia.",
      comment: "This book explores the systematic approach to public health in Australia, covering principles, guidelines, and procedures supporting the nation's public health system. It examines Australia's history, main health issues, and the roles of communities, NGOs, and government in improving health. Through case studies and examples, it illustrates effective public health interventions, emphasizing evidence-based practice, teamwork, and creativity in achieving better population health outcomes.",
      image: "/assets/recommendations/b2.jpg",
      url: "https://books.google.com.au/books/about/Public_Health_Practice_in_Australia.html?id=7HWB6vsAyDcC&redir_esc=y"
    },
    "Book2": {
      title: "Communication: Core Interpersonal Skills for Health Professionals",
      description: "O’Toole, G. (2012). Elsevier Health Sciences.",
      comment : "This manual emphasizes the importance of effective communication in healthcare settings, covering essential techniques like empathy, active listening, and nonverbal cues. It addresses interpersonal relationships within healthcare teams, handling difficult conversations, and communicating with diverse groups. Through case studies, it provides techniques for improving patient relationships and ensuring clear, compassionate communication, making it essential for healthcare workers seeking to enhance patient outcomes and professional connections.",
      image: "/assets/recommendations/b3.jpg",
      url: "https://www.google.com.au/books/edition/Communication/-Vfxc5TE3u0C?hl=en&gbpv=1&printsec=frontcover"
    },
    "Book4": {
      title: "Public Health Dentistry",
      description: "Soben Peter's comprehensive textbook on public health and dentistry.",
      comment: "Soben Peter's comprehensive textbook explores the intersection of public health and dentistry, covering concepts, procedures, and policies related to oral health. It addresses health promotion, community initiatives, preventive dentistry, and epidemiology, as well as the organization and management of dental care services. Suitable for practitioners, educators, and students, it includes sections on dental education, research methodologies, and global oral health challenges.",
      image: "/assets/recommendations/b4.jpg",
      url: "https://www.aryamedipublishing.com/Preventive_Community_Dentistry.htm"
    }
  };
  

const RecommendedLiterature = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 10 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} md="auto">
            <Typography variant="h4" gutterBottom>
              Recommended Literature
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" paragraph>
          Here are some recommended literature.
        </Typography>
        {!isMobile && (
          <Grid container spacing={2}>
            {Object.keys(recommendedLiterature).map((key, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={recommendedLiterature[key].image} alt={recommendedLiterature[key].title} style={{ width: '200px', height: '300px', marginRight: '10px' }} />
                  <Box>
                    <Typography variant="h5" gutterBottom>{recommendedLiterature[key].title}</Typography>
                    <Typography variant="body1" paragraph>
                      {recommendedLiterature[key].description} 
                      <a href={recommendedLiterature[key].url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}> ... Read More</a>
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {recommendedLiterature[key].comment}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        {isMobile && (
          <Box sx={{ overflowX: 'hidden' }}>
            {Object.keys(recommendedLiterature).map((key, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                >
                  <Typography variant="h5">{recommendedLiterature[key].title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={recommendedLiterature[key].image} alt={recommendedLiterature[key].title} style={{ width: '100px', height: '150px', marginRight: '20px' }} />
                    <Box>
                      <Typography variant="body1" paragraph>
                        {recommendedLiterature[key].description} 
                        <a href={recommendedLiterature[key].url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}> ... Read More</a>
                      </Typography>
                      <Typography variant="body1" paragraph>
                        {recommendedLiterature[key].comment}
                        </Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default RecommendedLiterature;
